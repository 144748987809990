export const dark = {
    body: "#202020",
    text: "#fff",
    bodyRgba: "32,32,32",
    textRgba: "255,255,255",
    grey: "#bebebe",

    fontxs: "0.75em",
    fontsm: "0.875em",
    fontem: "1em",
    fontlg: "1.25em",
    fontxl: "2em",
    fontxxl: "3em",
    fontxxxl: "5em",
    fontBig: "10em",

    navHight: '5rem'
};
